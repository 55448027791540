body {
    font-family: "Lato", serif;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes goToTop {
  0% {
      transform: translateY(-0.5rem);
  }
  100% {
      transform: translateY(1.3rem);
  }
}

.scrolling-container {
    display: flex;
    animation: scroll 15s linear infinite;
}
.animate-goToTop {
  animation: goToTop 1s linear infinite alternate-reverse;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    height: 6px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color:  #3a4a72; 
    border-radius: 10px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #0d2144; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; 
    border-radius: 10px;
  }
  
  .custom-bg-banner {
    background-image: url('../images/Career banner 1.webp');
    background-size: cover; 
    background-position: center;
  }
.custom-bg-contact{
  background-image: url('../images/Contact us.webp');
    background-size: cover; 
    background-position: center;

}

/* Add this in your global CSS or Tailwind config */
.scrollbar-thin {
  scrollbar-width: 2px; /* Firefox */
}

.scrollbar-thumb-gray-400 {
  scrollbar-color: #00274D transparent; /* Firefox */
}

.scrollbar-track-gray-200 {
  scrollbar-color: transparent #00274D; /* Firefox */
}
/* For Chrome/Edge/Safari */
::-webkit-scrollbar {
width: 5px; /* Set scrollbar width to 5px */
height: 5px; /* Optional: Set scrollbar height for horizontal scrollbars */
}

/* For Chrome/Edge/Safari */
::-webkit-scrollbar {
  height: 8px; /* Adjust the height of the scrollbar */
}
::-webkit-scrollbar-thumb {
  background-color: #0d2144;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

